import React, { useState, useEffect } from "react";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdministrativeStaffsActions } from "../../../Store/Administrative Staffs/action";
import HeaderBackground from "../../../common/SubpagesBGimage";

function AdministrativeStaff() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const [hoverPosition, setHoverPosition] = useState({});
  useEffect(() => {
    // dispatch({
    //   type: AdministrativeStaffsActions.GET_ADMINISTRATIVE_STAFFS_CHIEF_ADVISOR,
    // });
    dispatch({
      type: AdministrativeStaffsActions.GET_ADMINISTRATIVE_STAFFS_MENTORS_EXPERT_PANEL,
    });
  }, [dispatch]);

  const { AdministrativeChiefAdvisor, AdministrativeExpertmember } =
    useSelector((state) => state?.AdministrativeStaffReducer);

  const routeNames = {
    "/administrative-staffs": {
      menuName: "The People",
      subMenuName: "Administrative Staffs",
    },
  };
  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setHoverPosition({ top: `${y}px`, left: `${x}px` });
  };
  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  console.log("menuName", menuName);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <div>
      <main>
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <div className="section-padding">
          <div className="container">
            <div className="bord bord-thin-bottom d-flex align-items-center">
              <h6 className="sub-title main-color mb-25">
                Administrative staff
              </h6>
            </div>
            <h4 className="mt-25 fw-500 administrative-staffss expert-txtss">
              Chief Advisor and Member of Governing Council
            </h4>
            <div className="row">
              {AdministrativeChiefAdvisor?.length > 0 ? (
                AdministrativeChiefAdvisor.map((advisor) => (
                  <div className="col-md-3 mt-40" key={advisor.id}>
                    <div className="core-card">
                      <div className="card-container card-ress">
                        <div className="content-3">
                          <img
                            src={advisor.image}
                            alt={advisor.name}
                          />
                          <h6 className="main-color fw-500">{advisor.name}</h6>
                          <p className="fw-400 adminstrative-staffs">
                            {advisor.designation}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Chief Advisor data available</p>
              )}
            </div>
          </div>
        </div>

        <div className="container">
          <h4 className="mb-25 fw-500 administrative-staffss">
            Mentors Cum Expert Panel
          </h4>
          <div className="container">
            <div className="row">
              {AdministrativeExpertmember?.length > 0 ? (
                AdministrativeExpertmember.map((expert) => (
                  <div
                    className="col-md-6 col-lg-3 mb-4 adminstrative-staff-img-card"
                    key={expert.id}
                  >
                    <div
                      className="card-4"
                      data-tooltip-tit="More"
                      onMouseMove={handleMouseMove}
                    >
                      <img src={expert.image} alt={expert.name} />
                      <div className="card-content-4">
                        <h6 className="main-color fw-500 expert-name">
                          {expert.name}
                        </h6>
                        <p className="fw-400 adminstrative-staffs">
                          {expert.designation}
                        </p>
                      </div>
                      <div
                        className="hover-text"
                        style={{
                          top: hoverPosition.top,
                          left: hoverPosition.left,
                        }}
                      >
                        MORE
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Expert Panel data available</p>
              )}
            </div>
          </div>
        </div>
      </main>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default AdministrativeStaff;
