import React, { useState, useEffect } from "react";
import HeaderBackground from "../../../common/SubpagesBGimage";
import submenuservicon from "../../../assets/imgs/serv-icons/1.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { ExamCellActions } from "../../../Store/Infrastructure/action";

function Labs() {
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const routeNames = {
    "/labs": {
      menuName: "Infrastructure",
      subMenuName: "Labs",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_LABS_ACTIONS });
  }, [dispatch]);

  const { LabsData } = useSelector((state) => state?.ExamCellReducer);

  console.log("LabsData", LabsData);

  const sections = LabsData?.Labs || [];

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80 abt-uss">
        <div className="container">
          <div className="cal-action pb-10 mb-40 bord-thin-bottom about-us-texts">
            <h6 className="sub-title mb-15 main-color">LABS</h6>
          </div>
          <div className="sec-head mb-30"></div>

          <div className="row lg-marg" id="tabs">
            {/* Mobile View */}
            <div className="d-lg-none col-12">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end">
                  <div className="col-lg-12">
                    <ul className="rest">
                      {sections.map((section, index) => (
                        <li
                          key={section.section.lab_id}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <h5>
                            <span className="fz-18 opacity-7 mr-15">{`0${
                              index + 1
                            }`}</span>
                            {section.section.name}
                          </h5>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Desktop View */}
            <div className="col-lg-4 d-none d-lg-block side-txt">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end">
                  <div className="col-lg-12 iaq-padding">
                    <ul className="rest">
                      {sections.map((section, index) => (
                        <li
                          key={section.section.lab_id}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <div className="u-line linee psg-iaq-tabs">
                            <span className="icon ti-arrow-circle-right tabss-psg"></span>
                            <span className="text fz-20 psg-margin">
                              {section.section.name}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Tab Content */}
            <div className="col-lg-8 col-12">
              <div className="serv-tab-cont md-mb80 sticky-item">
                {sections.map((section, index) => (
                  <div
                    key={section.section.lab_id}
                    className={`tab-content ${
                      activeTab === `tabs-${index + 1}` ? "current" : ""
                    }`}
                    id={`tabs-${index + 1}`}
                  >
                    <div className="item">
                      <div className="sub-bg">
                        <div className="icon-img-60 mb-40">
                          <img src={submenuservicon} alt="" />
                        </div>
                        <div className="text">
                          <ul className="rest list-arrow mt-30 pt-30 bord-thin-top">
                            {section.contents.map((content, contentIndex) => (
                              <li key={contentIndex} className="pointtxt">
                                {section.contents.length > 1 && (
                                  <span className="icon psgiap-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="2.2em"
                                      height="2.2em"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        cx="12.1"
                                        cy="12.1"
                                        r="1"
                                        fill="none"
                                        stroke="#84e3c6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                      />
                                    </svg>
                                  </span>
                                )}
                                <p className="inline fw-400 fz-16 text ml-5 mt-2">
                                  {content.Lab_Content}{" "}
                                  {/* Adjust according to your data structure */}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Labs;
