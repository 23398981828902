import React, { useEffect } from 'react';
import Header from '../components/Layout/Header';
import Collaboration from '../components/Submenu/Collaboration/Collaboration';
import Footer from '../components/Layout/Footer';

function CollaborationIndex() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Collaboration />
      <Footer />
    </div>
  );
}

export default CollaborationIndex;
