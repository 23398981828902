import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlliedFacultyActions } from "../../../Store/Allied Faculty/action";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../../common/SubpagesBGimage";

function AlliedFaculty() {
  const dispatch = useDispatch();
  const [hoverPosition, setHoverPosition] = useState({
    top: "0px",
    left: "0px",
  });
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  // useEffect(() => {
  //   dispatch({ type: AlliedFacultyActions.GET_ALLIED_FACULTY });
  // }, [dispatch]);

  const { AlliedFacultyData } = useSelector(
    (state) => state?.AlliedFacultyReducer
  );

  const routeNames = {
    "/allied-faculty": {
      menuName: "The People",
      subMenuName: "Allied Faculty",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setHoverPosition({ top: `${y}px`, left: `${x}px` });
  };
  const handleImageClick = (staffId) => {
    setSelectedStaffId(staffId === selectedStaffId ? null : staffId);
  };

  return (
    <div id="smooth-content">
      <main className="main-bg blog-wrap">
      <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <div className="container allied-fac">
          <h2 className="sub-title main-color mb-25 bord-thin-bottom pb-15">
            Allied Faculty
          </h2>
          <div className="row ">
            {AlliedFacultyData &&
              AlliedFacultyData.map((faculty) => (
                <React.Fragment key={faculty.id}>
                  <div className="col-md-3 mb-20">
                    <div className="core-card">
                      <div
                        className={`card-container card-ress ${
                          selectedStaffId === faculty.id ? "selected" : ""
                        }`}
                      >
                        <div
                          className="content-3"
                          onClick={() => handleImageClick(faculty.id)}
                          onMouseMove={handleMouseMove}
                        >
                          <img
                            src={faculty.image}
                            alt={faculty.name}
                            className="core-faculty"
                            style={{ cursor: "pointer" }}
                          />
                          <h6 className="main-color fw-500 core-texts">
                            {faculty.name}
                          </h6>
                          <p className="fw-400 core-faculty-text">
                            {faculty.designation}
                          </p>
                          <div
                            className="hover-text"
                            style={{
                              top: hoverPosition.top,
                              left: hoverPosition.left,
                            }}
                          >
                            MORE
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {selectedStaffId == faculty.id && (
                    <div className="col-md-12">
                      <div className="card-description-4 visible">
                        <p className="staff-description">
                          {faculty.description}
                        </p>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>

          {/* <div className="description-wrapper">
            {AlliedFacultyData &&
              AlliedFacultyData.map((faculty) => (
                <div
                  className={`card-description-4 ${selectedStaffId === faculty.id ? 'visible' : 'hidden'}`}
                  key={faculty.id}
                >
                  <p className="staff-description">
                    {faculty.description}
                  </p>
                </div>
              ))}
          </div> */}
        </div>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default AlliedFaculty;
