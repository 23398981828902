import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StatutoryCommitteeActions } from "../../../Store/Statutory Committee/action";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { useNavigate } from "react-router-dom";


function Statutorycommitte() {
  const navigate = useNavigate();
  const [showCommittee, setShowCommittee] = useState(false);
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const routeNames = {
    "/statutory-committee": {
      menuName: "About Us",
      subMenuName: "Statutory Committee",
    },
  };



  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  // useEffect(() => {
  //   dispatch({ type: StatutoryCommitteeActions.GET_STATUTORY_COMMITTEE });
  // }, [dispatch]);

  const { StatutoryCommittee } = useSelector(
    (state) => state?.StatutoryCommitteeReducer
  );

  const handleClick = () => {
    setShowCommittee(true);
  };

  // Access the 'committee' array inside 'StatutoryCommittee'
  const committees = StatutoryCommittee?.committee || [];
  const committeeContent =
    StatutoryCommittee?.statutory_committee?.[0]?.committee_content || "";

    const handleCommitteeClick = (committeeName) => {
      // Navigate to a new page with the committee name
      navigate(`/detailpage/${committeeName}`, { state: { committeeName } });
    };
console.log("StatutoryCommittee",StatutoryCommittee);

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="statutory">
        <div className="container statutorycommittee">
          <div className="mb-60">
            <h6 className="sub-title main-color mb-25">Our Committees</h6>
            <div className="bord pt-25 bord-thin-top d-flex align-items-center flex-md-wrap">
              <div className="statutorycommittee1">
                <h4 className="fw-600 text-u ls1 sct">
                  Institutional{" "}
                  <span className="fw-200 committee-text">Committees</span>
                </h4>
              </div>
              <div className="committee-center">
                <p className="committee-center-para">
                  <div className="committee-center-para1">
                    {committeeContent}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-clr">
          <div className="container">
            <div className="buttons mb-60 cards-space-res">
            {committees.map((committee) => (
  <button
    key={committee.id}
    className="neumorphic"
    onClick={() => handleCommitteeClick(committee.committee_name)}
  >
    <img
      src={committee.pdf} // Display the image if needed
      // alt={committee.committee_name}
    />
    <span>{committee.committee_name}</span>
  </button>
))}
            </div>
          </div>
        </div>
      </div>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Statutorycommitte;