import React, { useRef, useState, useEffect, useMemo } from "react";
import HeaderBackground from "../../../common/SubpagesBGimage";
import submenuservicon from "../../../assets/imgs/serv-icons/1.png";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import pdf from "../../../assets/imgs/dummy pdf.pdf";
import share from "../../../assets/imgs/shareicon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ExamCellActions } from "../../../Store/ExamCell/action";

function Examcell() {
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
 
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleVisibilityCircular = () => {
    setIsVisible((prevState) => !prevState);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const routeNames = {
    "/exam-cell": {
      menuName: "Pedagogy",
      subMenuName: "Exam Cell",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Static sections and contents
  const sections = [
    {
      section: {
        section_id: 1,
        section_name: "About",
      },
      contentType: "about",
      content: [],
    },
    {
      section: {
        section_id: 2,
        section_name: "People",
      },
      contentType: "cards",
      content: [
        {
          title: "Ar.Hansie J Diaz",
          description: "Assistant Professor",
        },
        {
          title: "Ar.Suwatha K",
          description: "Assistant Professor",
        },
      ],
    },
    {
      section: {
        section_id: 3,
        section_name: "Circulars",
      },
      contentType: "pdf",
      content: [
        { fileName: "ExamCell_Facilities.pdf", fileUrl: "/path/to/file" },
      ],
    },
    {
      section: {
        section_id: 4,
        section_name: "University Results",
      },
      contentType: "result",
      content: [
        { fileName: "ExamCell_Facilities.pdf", fileUrl: "/path/to/file" },
      ],
    },
    {
      section: {
        section_id: 5,
        section_name: "Useful Links",
      },
      contentType: "links",
      content: [
        { fileName: "ExamCell_Facilities.pdf", fileUrl: "/path/to/file" },
      ],
    },
    {
      section: {
        section_id: 6,
        section_name: "Contact us",
      },
      contentType: "contact-us",
      content: [],
    },
  ];

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL_ABOUT });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL_UNIRESULT });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: ExamCellActions.GET_EXAM_CELL_USEFUL_LINKS });
  }, [dispatch]);

  const { ExamCellUsefulLinks } = useSelector((state) => state.ExamCellReducer);
  const { ExamCellUniRes } = useSelector((state) => state.ExamCellReducer);

  const { ExamCellAbout } = useSelector((state) => state.ExamCellReducer);
  const { ExamCellData } = useSelector((state) => state.ExamCellReducer);

  // Log data
  console.log("ExamCellUsefulLinks", ExamCellUsefulLinks);

  console.log("ExamCellUnires", ExamCellUniRes);

  console.log("ExamCellAbout", ExamCellAbout);
  console.log("people", ExamCellData);

  // Prepare About Points
  const points =
    ExamCellAbout && ExamCellAbout.length > 0
      ? Object.keys(ExamCellAbout[0])
          .filter((key) => key.startsWith("point") && ExamCellAbout[0][key])
          .map((key) => ExamCellAbout[0][key])
      : [];

  // Validate People Data
  const peopleData =
    ExamCellData?.status === "success" && ExamCellData.data
      ? ExamCellData.data
      : [];

      
      const toggleVisibilityres = (index) => {
        setIsVisible((prevState) => {
          const newState = [...prevState];
          newState[index] = !newState[index]; // Toggle the specific index
          return newState;
        });
      };
      
      const [isVisible, setIsVisible] = useState(
        Array(ExamCellUniRes.length).fill(false)
      );
  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80 abt-uss">
        <div className="container">
          <div className="cal-action pb-10 mb-40 bord-thin-bottom about-us-texts">
            <h6 className="sub-title mb-15 main-color">Exam Cell</h6>
          </div>
          <div className="sec-head mb-30"></div>

          <div className="row lg-marg" id="tabs">
            {/* Mobile View */}
            <div className="d-lg-none col-12">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end">
                  <div className="col-lg-12">
                    <ul className="rest">
                      {sections.map((section, index) => (
                        <li
                          key={section.section.section_id}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <h5>
                            <span className="fz-18 opacity-7 mr-15">{`0${
                              index + 1
                            }`}</span>
                            {section.section.section_name}
                          </h5>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Desktop View */}
            <div className="col-lg-4 d-none d-lg-block side-txt">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end">
                  <div className="col-lg-12 iaq-padding">
                    <ul className="rest">
                      {sections.map((section, index) => (
                        <li
                          key={section.section.section_id}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === `tabs-${index + 1}` ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(`tabs-${index + 1}`)}
                        >
                          <div className="u-line linee psg-iaq-tabs">
                            <span className="icon ti-arrow-circle-right tabss-psg "></span>
                            <span className="text fz-20 psg-margin">
                              {section.section.section_name}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Tab Content */}
            <div className="col-lg-8 col-12">
              <div className="serv-tab-cont md-mb80 sticky-item">
                {sections.map((section, index) => (
                  <div
                    key={section.section.section_id}
                    className={`tab-content ${
                      activeTab === `tabs-${index + 1}` ? "current" : ""
                    }`}
                    id={`tabs-${index + 1}`}
                  >
                    <div className="item">
                      <div className="sub-bg">
                        <div className="icon-img-60 mb-40">
                          <img src={submenuservicon} alt="" />
                        </div>
                        <div className="text">
                          {/* Handle people */}
                          {section.contentType === "cards" && (
                            <>
                              <h5
                                style={{ color: "#3a4197" }}
                                className="mb-20"
                              >
                                People
                              </h5>
                              <div className="cards-container row mb-50">
                                {peopleData.length > 0 && (
                                  <div className="cards-container row mb-50">
                                    {peopleData.map((card) => (
                                      <div
                                        key={card.id}
                                        className="card-item col-lg-6 col-md-6 col-sm-12 mb-4"
                                      >
                                        <div className="card shadow-sm p-3 cards-examcell h-100">
                                          <h5 className="fw-bold">
                                            {card.name}
                                          </h5>
                                          <p className="text-secondary mb-2">
                                            {card.designation}
                                          </p>
                                          {/* <p className="text-primary">{card.title}</p> */}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {/* Loading State */}
                                {points.length === 0 &&
                                  peopleData.length === 0 && (
                                    <div>Loading...</div>
                                  )}
                              </div>
                            </>
                          )}
                          {/* Handle circulars */}
                          {section.contentType === "pdf" && (
                            <div className="examcell-circular">
                              <h5 style={{ color: "#3a4197" }}>Circulars</h5>
                              <br />
                              <h6 style={{ color: "#3a4197" }}>
                                Internal Circulars
                              </h6>
                              <div class="c-natification__item mt-30">
                                <a
                                  href={pdf}
                                  target="_blank"
                                  class="link"
                                  rel="noreferrer"
                                >
                                  <div class="date">
                                    <b>Internal Assessment Test - II </b>
                                  </div>
                                  <h6 className="mt-10">
                                    1<sup>st</sup> Semester B.E, B.Tech Students
                                  </h6>
                                </a>
                              </div>
                              <br />

                              <h6 style={{ color: "#3a4197" }}>
                                Anna University Circulars
                              </h6>
                              <br />
                              <div class="c-natification__item">
                                <a
                                  href="assets/pdf/examcell/Acadmic_schedule/00. Academic Sch UG (Higher Semester) - Affl.pdf"
                                  target="_blank"
                                  class="link"
                                >
                                  <div class="date">
                                    <b>Academic Schedule</b>
                                  </div>
                                  <h6>
                                    2<sup>nd</sup> 3<sup>rd</sup> &amp; Final
                                    Year - B.E &amp; B.Tech{" "}
                                  </h6>
                                </a>
                              </div>
                              <br />
                              <hr />
                              <br />
                              <h5 style={{ color: "#3a4197" }}>Archive</h5>
                              <div class="panel panel-default">
                                <div
                                  class="panel-heading"
                                  role="tab"
                                  id="headingOne"
                                >
                                  <h4 class="panel-title">
                                    <a
                                      onClick={toggleVisibilityCircular}
                                      role="button"
                                      data-toggle="collapse"
                                      data-parent="#accordion"
                                      href="#collapse8"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                      class=""
                                    >
                                      <i
                                        style={{ color: "white" }}
                                        class="more-less fa fa-angle-down"
                                      ></i>{" "}
                                      &nbsp;
                                      <strong class="text-white">
                                        Circulars 2023-2024{" "}
                                      </strong>
                                    </a>
                                  </h4>
                                </div>

                                {isVisible && (
                                  <div
                                    class="panel-collapse collapse show"
                                    role="tabpanel"
                                    aria-labelledby="headingOne"
                                  >
                                    <div class="panel-body ">
                                      <p class="collapseinner"></p>
                                      <div class="collapse-inner">
                                        <h6 style={{ color: "#3a4197" }}>
                                          Anna University Circulars
                                        </h6>

                                        <br />

                                        <div class="c-natification__item">
                                          <a
                                            href="assets/pdf/examcell/Acadmic_schedule/UG &amp; MBA - Higher Sem.pdf"
                                            target="_blank"
                                            class="link"
                                          >
                                            <div class="date">
                                              <b>Academic Schedule</b>
                                            </div>
                                            <h6>
                                              3<sup>rd</sup> &amp; Final Year{" "}
                                            </h6>
                                          </a>
                                        </div>

                                        <div class="c-natification__item">
                                          <a
                                            href="assets/pdf/examcell/Acadmic_schedule/UG &amp; PG - Semester II, IV.pdf"
                                            target="_blank"
                                            class="link"
                                          >
                                            <h6>
                                              UG &amp; PG 1<sup>st</sup> &amp; 2
                                              <sup>nd</sup> Year{" "}
                                            </h6>
                                          </a>
                                        </div>

                                        <br />

                                        <h6 style={{ color: "#3a4197" }}>
                                          End Semester Examinations Time Table
                                        </h6>
                                        <br />

                                        <div class="c-natification__item">
                                          <a
                                            href="assets/exam_cell/pdf/aucr2021_LANDSCAPE.pdf"
                                            target="_blank"
                                            class="link"
                                          >
                                            <div class="date">
                                              <b>Nov/Dec 2023</b>
                                            </div>
                                            <h6>
                                              Odd Semester - Examination Time
                                              Table{" "}
                                            </h6>
                                          </a>
                                        </div>

                                        <div class="c-natification__item">
                                          <a
                                            href="assets/exam_cell/pdf/SMART INDIA HACKATHON -2023 RESCHEDULE NOV DEC 2023.pdf"
                                            target="_blank"
                                            class="link"
                                          >
                                            <h6>
                                              Reschedule Nov/Dec 2023 (Smart
                                              India Hackathon){" "}
                                            </h6>
                                          </a>
                                        </div>
                                        <br />

                                        <hr />

                                        <br />

                                        <h6 style={{ color: "#3a4197" }}>
                                          Internal Circulars
                                        </h6>
                                        <br />

                                        <div class="c-natification__item">
                                          <a
                                            href="assets/exam_cell/pdf/may24/i yr -tt.pdf"
                                            target="_blank"
                                            class="link"
                                          >
                                            <div class="date">
                                              <b>
                                                Internal Assessment Test - II
                                              </b>
                                            </div>
                                            <h6>
                                              2<sup>nd</sup> Semester B.E
                                              Students
                                            </h6>
                                          </a>
                                        </div>
                                        <br />
                                        <div class="c-natification__item">
                                          <a
                                            href="assets/exam_cell/pdf/may24/II yr-tt.pdf"
                                            target="_blank"
                                            class="link"
                                          >
                                            <div class="date">
                                              <b>
                                                Internal Assessment Test - II
                                              </b>
                                            </div>
                                            <h6>
                                              4<sup>th</sup> Semester B.E
                                              Students
                                            </h6>
                                          </a>
                                        </div>

                                        <br />

                                        <div class="c-natification__item">
                                          <a
                                            href="assets/exam_cell/pdf/may24/I M.E- tt.pdf"
                                            target="_blank"
                                            class="link"
                                          >
                                            <div class="date">
                                              <b>
                                                Internal Assessment Test - II
                                              </b>
                                            </div>
                                            <h6>
                                              2<sup>nd</sup> Semester M.E
                                              Students
                                            </h6>
                                          </a>
                                        </div>
                                      </div>
                                      <p></p>
                                      <hr />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {/* handle university res */}
                          {section.contentType === "result" && (
                            <div>
                              <h5 style={{ color: "#3a4197" }}>
                                Students Academic Performance in End Semester
                                Examinations
                              </h5>
                              <br />
                              {ExamCellUniRes.map((item, index) => (
                                <div
                                  className="panel panel-default"
                                  key={item.id}
                                >
                                  <div
                                    className="panel-heading"
                                    role="tab"
                                    id={`heading-${item.id}`}
                                  >
                                    <h4 className="panel-title">
                                      <a
                                        role="button"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        onClick={() =>
                                          toggleVisibilityres(index)
                                        }
                                        aria-expanded="true"
                                        aria-controls={`collapse-${item.id}`}
                                        className=""
                                      >
                                        <i
                                          style={{ color: "white" }}
                                          className="more-less fa fa-angle-down"
                                        ></i>
                                        &nbsp;
                                        <strong className="text-white">
                                          {item.title}
                                        </strong>
                                      </a>
                                    </h4>
                                  </div>
                                  {isVisible[index] && ( // Dynamically toggle visibility for each item
                                    <div
                                      className="panel-collapse collapse show"
                                      role="tabpanel"
                                      aria-labelledby={`heading-${item.id}`}
                                    >
                                      <div className="panel-body">
                                        <div className="collapse-inner">
                                          <a
                                            href={item.pdf}
                                            target="_blank"
                                            className="btn btn-secondary me-2"
                                            tabIndex="0"
                                          >
                                            <span style={{ color: "white" }}>
                                              {item.date}
                                            </span>
                                            &nbsp;
                                            <img
                                              src={share}
                                              alt="share"
                                              className="shareiconres"
                                            ></img>
                                          </a>
                                        </div>
                                        <hr />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}

                          {/* handling uni links */}
                          {section.contentType === "links" && (
                            <div>
                              <h5 style={{ color: "#3a4197" }}>Useful Links</h5>
                              <br />

                              <a
                                target="_blank"
                                href="https://aucoe.annauniv.edu/timetable_auc.php"
                                className="usefullinks-examcell"
                              >
                                <b>
                                  • Anna University End Semester examination
                                  Time Table
                                </b>
                              </a>
                              <br />
                              <br />
                              <a
                                target="_blank"
                                href="https://aucoe.annauniv.edu/timetable_auc.php"
                                className="usefullinks-examcell"
                              >
                                <b>• Anna University Result</b>
                              </a>
                              <br />
                              <br />
                              <a
                                target="_blank"
                                href="https://aucoe.annauniv.edu/timetable_auc.php"
                                className="usefullinks-examcell"
                              >
                                <b>
                                  • Applying Transcripts, Duplicate Grade sheet,
                                  Name Correction
                                </b>
                              </a>
                              <br />
                              <br />
                              <a
                                target="_blank"
                                href="https://aucoe.annauniv.edu/timetable_auc.php"
                                className="usefullinks-examcell"
                              >
                                <b>
                                  • Anna university Genuineness Verification
                                  Certificate
                                </b>
                              </a>
                            </div>
                          )}
                          {/* handling contact us */}
                          {section.contentType === "contact-us" && (
                            <div>
                              <h5 style={{ color: "#3a4197" }}>
                                Contact Details
                              </h5>
                              <div>
                                <strong>Exam Cell</strong>
                                <br />
                                PSG Institute of Technology and Applied Research
                              </div>
                              <div>Avinashi Road, Neelambur</div>
                              <div>Coimbatore - 641 062</div>
                              <div>Tamilnadu.</div>
                              <br />
                              <div>
                                Phone No :{" "}
                                <a href="tel:04223933449">0422 3933 449</a>
                              </div>
                              <div>
                                Mail:{" "}
                                <a href="mailto:examcell@psgitech.ac.in">
                                  <strong> examcell@psgitech.ac.in</strong>
                                </a>
                              </div>
                            </div>
                          )}
                          {/* handling about */}
                          {section.contentType === "about" &&
                            points.length > 0 && (
                              <div>
                                <h5 style={{ color: "#3a4197" }}>About</h5>
                                <ul className="about-examcell">
                                  {points.map((point, index) => (
                                    <React.Fragment key={index}>
                                      <li>• &nbsp; {point}</li>
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}
export default Examcell;
