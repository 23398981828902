import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";

function VideoSection() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_LATESTVIDEO });
  }, [dispatch]);

  const { LatestVideo } = useSelector((state) => state.HomePageReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const sliderRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  const openPopup = (url) => {
    setVideoUrl(url);
    setShowPopup(true);
  };

  const closePopup = () => {
    setVideoUrl("");
    setShowPopup(false);
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Intersection ratio threshold at which the callback will be executed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasScrolledIntoView) {
          setAnimateHeading(true); 
          setHasScrolledIntoView(true); 
          observer.disconnect(); 
        }
      });
    }, options);

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [hasScrolledIntoView]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <section className="services section-padding sub-bg sec-pad-vid ">
      <div className="container">
        <div className="sec-head mb-30">
          <h6 className="sub-title main-color mb-25 home-gap">Watch our videos</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600 ls1 d-rotate wow ${
                animateHeading ? "heading-animation" : ""
              }`}
            >
              <span className="rotate-text portfolio-text vid-txtes">
                Our Latest Videos
              </span>
            </h2>
            <div className="ml-auto">
              <div className="swiper-arrow-control">
                <div className="swiper-button-prev" onClick={prevSlide}>
                  <span className="ti-arrow-circle-left vid-arrow"></span>
                </div>
                <div className="swiper-button-next" onClick={nextSlide}>
                  <span className="ti-arrow-circle-right vid-arrow"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Slider {...settings} ref={sliderRef}>
          {LatestVideo.map((item, index) => (
            <div className="swiper-wrapper" key={index}>
              <div className="item-box radius-15 res-vid">
                <div className="vid-con" onClick={() => openPopup(item.link)}>
                  <iframe
                    width="400"
                    height="315"
                    src={item.link}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ pointerEvents: "none" }}
                  ></iframe>
                </div>
                <h5
                  className={`text-videosection ${
                    animateHeading ? "heading-animation" : ""
                  }`}
                >
                  {item.title.slice(0,35)}...
                </h5>
                <p
                  className={`${
                    animateHeading ? "heading-animation" : ""
                  } video-texts`}
                >
                  {item.description.slice(0, 100)}...
                </p>
              </div>
            </div>
          ))}
        </Slider>
        {/* Close Button */}
        {showPopup && (
          <div className="close-btn-videosection" onClick={closePopup}>
            <i className="fas fa-times"></i>
          </div>
        )}
        {/* Video Popup */}
        {showPopup && (
          <div className="video-popup-videosection">
            <div className="video-container-videosection">
              <div className="video-content-videosection">
                <iframe
                  width="760"
                  height="515"
                  src={videoUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default VideoSection;
