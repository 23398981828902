import { CocurricularActions } from "./action";
const initialState ={
    CocurricularData:[]
}
export const CocurricularReducer =(state = initialState, action)=>{
    switch (action.type){
        case CocurricularActions.SET_COCURRICULAR:
            return {
                ...state,
                CocurricularData: action.payload,
            };
            default:
                return state;
    }
}