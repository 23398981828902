import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";
import CloseButton from "react-bootstrap/CloseButton";
import Cursor from "../../common/Cursor";

function NewsandEvents() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const dispatch = useDispatch();
  const [animateNews, setAnimateNews] = useState(Array(4).fill(false));
  const newsRefs = useRef([]);

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_NEWS_AND_EVENTS });
  }, [dispatch]);

  const { NewsAndEvents } = useSelector((state) => state.HomePageReducer);

  const handleOpenModal = (news) => {
    if (news.images.length <= 1) {
      setSelectedNews(news);
      setShowModal(true);
    } else {
      navigate("/Gallery", { state: { id: news.id, title: news.title, description: news.description, images: news.images } });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNews(null);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimateHeading(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (headingRef.current) observer.observe(headingRef.current);

    return () => headingRef.current && observer.unobserve(headingRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = newsRefs.current.indexOf(entry.target);
          if (entry.isIntersecting && index !== -1) {
            setAnimateNews((prev) => {
              const updated = [...prev];
              updated[index] = true;
              return updated;
            });
          }
        });
      },
      { threshold: 0.2 }
    );

    newsRefs.current.forEach((ref) => ref && observer.observe(ref));

    return () => newsRefs.current.forEach((ref) => ref && observer.unobserve(ref));
  }, [NewsAndEvents]);

  const handleViewMoreClick = () => navigate("/newsandevents");

  return (
    <div>
      <section className="services-boxs section-padding">
        <Cursor />
        <div className="container">
          <h6 className="sub-title main-color mb-25">News & Events</h6>
          <div className="sec-head mb-20">
            <div className="bord pt-20 bord-thin-top d-flex align-items-center">
            <h2
                ref={headingRef}
                className={`fw-600 ls1 ${
                  animateHeading ? "heading-animation" : ""
                }portfolio-text`}
              >
                Our Latest News
              </h2>
            </div>
          </div>
          <div className="row pt-30">
            {NewsAndEvents.slice(0, 4).map((news, index) => (
              <div
                key={index}
                ref={(el) => (newsRefs.current[index] = el)}
                className={`col-lg-3 col-md-6 items d-rotate animated ${animateNews[index] ? "news-animation" : ""}`}
              >
                <div className="item-box bg md-mb50 rotate-text res-text" onClick={() => handleOpenModal(news)}>
                  <div className="icon">
                    <img src={news.thumbnail} alt={`Thumbnail ${index}`} className="news-thumbnail" />
                  </div>
                  <h5 className="mb-15 news-bot-wrap">
                    <a className="news-title">{news.title}</a>
                  </h5>
                </div>
              </div>
            ))}
          </div>
          <div className="flr mt-30">
            <a className="go-more" onClick={handleViewMoreClick} style={{ cursor: "pointer" }}>
              <span className="text">View more</span>
              <span className="icon ti-arrow-circle-right news-texts"></span>
            </a>
          </div>
        </div>
        <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">
          <CloseButton className="close-button" onClick={handleCloseModal} />
          <Modal.Header>
            <Modal.Title className="animate-charcter">{selectedNews && selectedNews.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedNews && (
              <>
                {selectedNews.images.length > 0 && (
                  <div className="icon">
                    <img className="image-newsandevents" src={selectedNews.images[0].image} alt={selectedNews.images[0].content} />
                    <p className="image-overlay">{selectedNews.images[0].content}</p>
                  </div>
                )}
                <p>{selectedNews.description}</p>
              </>
            )}
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
}

export default NewsandEvents;
