import React, { useState } from "react";
import submenuservicon from "../../../assets/imgs/serv-icons/1.png";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";

function PsgLap() {
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const routeNames = {
    "/psg-iap": { menuName: "About Us", subMenuName: "PSG Iap" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const introContent = [
    
      <p>
     Architecture is more than just building design; it's about creating spaces with sense, soul, sanctity, life, and character and an architect evolves them by skilfully integrating art, science, and engineering through an infinite ingenious process. With the objective of nurturing such ingenious minds, PSG IAP offers architectural education with unique pedagogical approaches which perfectly blend traditional principles with modern and advanced technologies. Our Vision & Mission is being made possible through our multi-layered approach comprising Strong Faculty Team,Expert Panel of Architects,Association with Eminent Architects of the Country,Lectures, Workshops, and Seminars,Outreach,Travels and Study Tours,State-of-the-Art Infrastructure.
      </p>
   
  ]

  const sections = [
    {
      section: {
        section_id: 1,
        section_name: "Our Mission",
      },
      contents: [
        {
          section_content: (
            <>
              <>
              “To provide intellectually stimulating environment, where
                students find their creative identities and emerge {" "}
                <b style={{color:"#002244"}}>
                with an interdisciplinary education in Art, Architecture, Planning,
                  Design and Technology
                </b>{" "}
                along with a deep-rooted knowledge of one’s own surroundings,
                social awareness, and grounding in cultural heritage, resulting
                in ethical, responsible professionals.”
              </>
            </>
          ),
        },
        {
          section_content: (
            <>
              To provide an enriching and experiential learning environment of
              the highest quality and integrity PSG IAP,
              <br/>
               <b style={{color:"#002244", fontStyle:"italic"}} > VALUES</b>
            </>
          ),
        },
        {
          section_content: (
            <ul className="custom-bullet-list">
              <li>Quality teaching and learning process.</li>
              <li>
                Opportunities for individuals to realize their full potential.
              </li>
              <li>The rigor, joy, and fulfillment of intellectual discovery.</li>
              <li>Supportive and congenial relationships.</li>
              <li>Respect for diversity and inclusivity.</li>
              <li>Service to society.</li>
            </ul>
          ),
        },
      ],
    },
    {
      section: {
        section_id: 2,
        section_name: "Our Vision",
      },
      contents: [
        {
          section_content:(
            <>
            “To achieve academic excellence in Architecture and Planning through{" "}
            <b style={{ color: "#002244" }}>
            innovating, creating, acquiring and disseminating knowledge using sustainable local and global practices and enhancing the quality of society through responsible built environment.”
            </b>
          </>
          
         
          )
        },
      ],
    },
    {
      section: {
        section_id: 3,
        section_name: "Coal",
      },
      contents: [
        {
          section_content:
            "A pedagogy-centred curriculum sets out to produce students who are consistently creative selves, lifetime learners with an independent critical and artistic agency rooted in the essence of what it is to be human, whose consciousness participates constructively in the world, whose agency and commitment remain unaffected by superficial changes of context. The challenge is captured in a statement by Richard Shaull (which draws from the philosophy of Paolo Freire):",
        },
        {
          section_content:
            "“There is no such thing as a neutral education process. Education either functions as an instrument which is used to facilitate the integration of generations into the logic of the present system and bring about conformity to it, or it becomes the ‘practice of freedom’, the means by which men and women deal critically with reality and discover how to participate in the transformation of their world.” (Freire, 2019).",
        },
      ],
    },
  ];

  return (
    <div id="smooth-content">
      <main className="main-bg blog-wrap">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <section className="services-tab section-padding mb-80 abt-uss">
          <div className="container">
            <div className="cal-action pb-10 mb-40 bord-thin-bottom about-us-texts">
              <h6 className="sub-title mb-15 main-color">About Us</h6>
            </div>
            <div className="sec-head mb-30"></div>
            <div className="text mb-50">
            {introContent.map((content, index) => (
          <p key={index} className="para-psg">
            {index >= 3 ? <li>{content}</li> : content}
          </p>
        ))}
            </div>

            <div className="row lg-marg" id="tabs">
              <div className="d-lg-none col-12">
                <div className="serv-tab-link tab-links full-width pt-40">
                  <div className="row justify-content-end">
                    <div className="col-lg-12">
                      <ul className="rest">
                        {sections.map((section, index) => (
                          <li
                            key={section.section.section_id}
                            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                              activeTab === `tabs-${index + 1}` ? "current" : ""
                            }`}
                            onClick={() => handleTabClick(`tabs-${index + 1}`)}
                          >
                            <h5>
                              <span className="fz-18 opacity-7 mr-15">{`0${
                                index + 1
                              }`}</span>
                              {section.section.section_name}
                            </h5>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block side-txt">
                <div className="serv-tab-link tab-links full-width pt-40">
                  <div className="row justify-content-end">
                    <div className="col-lg-12 iaq-padding">
                      <ul className="rest">
                        {sections.map((section, index) => (
                          <li
                            key={section.section.section_id}
                            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                              activeTab === `tabs-${index + 1}` ? "current" : ""
                            }`}
                            onClick={() => handleTabClick(`tabs-${index + 1}`)}
                          >
                            <div className="u-line linee psg-iaq-tabs">
                              <span className="icon ti-arrow-circle-right tabss-psg "></span>
                              <span className="text fz-20 psg-margin">
                                {section.section.section_name}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                <div className="serv-tab-cont md-mb80 sticky-item">
                  {sections.map((section, index) => (
                    <div
                      key={section.section.section_id}
                      className={`tab-content ${
                        activeTab === `tabs-${index + 1}` ? "current" : ""
                      }`}
                      id={`tabs-${index + 1}`}
                    >
                      <div className="item">
                        <div className="sub-bg">
                          <div className="icon-img-60 mb-40">
                            <img src={submenuservicon} alt="" />
                          </div>
                          <div className="text">
                            <ul className="rest list-arrow mt-30 pt-30 bord-thin-top">
                              {section.contents.map((content, contentIndex) => (
                                <li key={contentIndex} className="pointtxt">
                                  <p className="inline fw-600 fz-16 text ml-5 mt-2">
                                    {content.section_content}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default PsgLap;