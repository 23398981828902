import React, { useState, useEffect } from "react";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import { AdministrationAction } from "../../../Store/Administration/action";
import { useDispatch, useSelector } from "react-redux";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { TheTrustAction } from "../../../Store/The Trust/action";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Administration() {
  const [activeSection, setActiveSection] = useState("theTrust");
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const routeNames = {
    "/administration": { menuName: "About Us", subMenuName: "Administration" },
  };
  const [trusteeData, setTrusteeData] = useState(null);

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section === activeSection ? null : section);
  };
 

  useEffect(() => {
    AOS.init({
      duration: 1200, // set duration for the animations
      once: true, // whether animation should happen only once
    });
  }, []);

  useEffect(() => {
    // dispatch({ type: AdministrationAction.GET_ADMINISTRATION_ORGANIZATION });
    dispatch({
      type: AdministrationAction.GET_ADMINISTRATION_SECRETARYMESSAGE,
    });
    dispatch({ type: AdministrationAction.GET_GOVERNINGCOUNCILMEMBERS });
  }, [dispatch]);

  const { GoverningCouncil } = useSelector(
    (state) => state?.AdministrationReducer
  );
  const { SecretaryInfo } = useSelector(
    (state) => state?.AdministrationReducer
  );
  const { OrganizationSchedule } = useSelector(
    (state) => state?.AdministrationReducer
  );

  const secretaryData = SecretaryInfo?.[0] || {};
  const messageParagraphs =
    secretaryData?.message
      ?.split("\r\n")
      .filter((paragraph) => paragraph.trim() !== "") || [];

  useEffect(() => {
    dispatch({ type: TheTrustAction.GET_THE_TRUST_INFO_MSG });
  }, [dispatch]);

  const { TheTrustInfoandMsg } = useSelector((state) => state?.TheTrustReducer);

  useEffect(() => {
    if (TheTrustInfoandMsg?.length) {
      setTrusteeData(TheTrustInfoandMsg[0]);
    }
  }, [TheTrustInfoandMsg]);

  if (!trusteeData) return <div>Loading...</div>;

  const GoverningCouncilMember = ({ name, position, additionalInfo }) => (
    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
      <div className="card-2 mt-40">
        <div className="card-overlay-2"></div>
        <div className="card-inner-2">
          <h6 className="fw-700">{name}</h6>
          <p>{position}</p>
          <p className="main-color">{additionalInfo}</p>
        </div>
      </div>
    </div>
  );

  
  return (
    <div>
      <main className="main-bg blog-wrap">
        <HeaderBackground
          className="admins-res"
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <section className="work-minimal section-padding sub-bg administration-page">
          <div className="container-xxl">
            <div className="row  admin-top">
              <div className="filtering col-12 mb-50 mt-60 text-center">
                <div>
                  <span
                    className={activeSection === "theTrust" ? "active" : ""}
                    onClick={() => handleSectionClick("theTrust")}
                  >
      Trustee Message
                  </span>

                  <span
                    className={
                      activeSection === "secretaryInfo" ? "active" : ""
                    }
                    onClick={() => handleSectionClick("secretaryInfo")}
                  >
               Secretary Message
                  </span>
                  <span
                    className={
                      activeSection === "governingCouncil" ? "active" : ""
                    }
                    onClick={() => handleSectionClick("governingCouncil")}
                  >
                    Governing Council
                  </span>
                </div>
              </div>
            </div>
            <div className="gallery row stand-marg administrations">
              {/* The Trust Section */}
              <div
                className="col-12 items trust-info"
                style={{
                  display:
                    activeSection === "theTrust" || activeSection === null
                      ? "block"
                      : "none",
                }}
              >
                <div className="item mt-0">
                  {/* Content for The Trust */}
                  <section className="about" data-scroll-index="2">
                    <div className="container mt-30">
                      <div className="row md-marg manage-wrap trustee-section">
                        <div className="col-lg-6 trustee-message">
                          <h6 className="sub-title main-color mb-15 fw-320">
                            Trustee Message
                          </h6>
                          <h2 className="fw-400 d-rotate wow fz-35">
                            <span className="rotate-text trustee-msg trustee-name">
                              {trusteeData?.name}
                            </span>
                            <h6 className="text-u ls1 opacity-8 rotate-text fw-400 ">
                              {trusteeData?.position}
                            </h6>
                            <div className="info">
                              <p className="nowrap">
                                <span>
                                  {/* Phone Icon */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"
                                    />
                                  </svg>
                                </span>
                                &nbsp; &nbsp; {trusteeData?.mobile}
                              </p>
                              <p className="nowrap">
                                <span>
                                  {/* Email Icon */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M1.75 2h12.5c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0 1 14.25 14H1.75A1.75 1.75 0 0 1 0 12.25v-8.5C0 2.784.784 2 1.75 2M1.5 12.251c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25V5.809L8.38 9.397a.75.75 0 0 1-.76 0L1.5 5.809zm13-8.181v-.32a.25.25 0 0 0-.25-.25H1.75a.25.25 0 0 0-.25.25v.32L8 7.88Z"
                                    />
                                  </svg>
                                </span>
                                &nbsp; &nbsp; {trusteeData?.email}
                              </p>
                            </div>
                          </h2>
                          <div className="d-flex align-items-end mt-30 trustee-texts">
                            <div>
                              {messageParagraphs
                                .slice(0, 2)
                                .map((paragraph, index) => (
                                  <p key={index}>{paragraph}</p>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 trustee-image-container">
                          <div className="img-vid">
                            {trusteeData?.image ? (
                              <img
                                src={trusteeData.image}
                                alt="Trustee"
                                className="trustee-image"
                              />
                            ) : (
                              <p>Image not available</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row md-marg manage-wrap trustee-section">
                        {messageParagraphs
                          .slice(2, 3)
                          .map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                          ))}
                        {messageParagraphs
                          .slice(3, 4)
                          .map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                          ))}
                      </div>
                    </div>
                  </section>
                  <section className="services-details section-padding">
                    <div className="container">
                      <div className="sec-head text-center mb-80">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <div data-aos="zoom-in-up">
                              <h4 className="italic text-zoomin">
                                "{trusteeData?.quote}"
                              </h4>
                              <div>
                                <span className="sub-title main-color mt-20">
                                  {trusteeData?.author}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              {/* Governing Council Members */}
              <div
                className="col-12 items art"
                style={{
                  display:
                    activeSection === "governingCouncil" ||
                    activeSection === null
                      ? "block"
                      : "none",
                }}
              >
                <div className="item mt-0">
                  <div className="mt-30">
                    <div className="container">
                      <h2 className="sub-title main-color mb-25">
                        Governing Council Members
                      </h2>
                      <div className="row bord-thin-top">
                        {GoverningCouncil &&
                          GoverningCouncil.map((member) => (
                            <GoverningCouncilMember
                              key={member.id}
                              name={member.member_name}
                              position={member.designation}
                              additionalInfo={member.Committee}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Secretary Info */}
              <div
                className="col-12 items brand sec-info"
                style={{
                  display:
                    activeSection === "secretaryInfo" || activeSection === null
                      ? "block"
                      : "none",
                }}
              >
                <div className="item mt-0  secrat">
                  {SecretaryInfo && SecretaryInfo.length > 0 && (
                    <section className="about" data-scroll-index="2">
                      <div className="container secretary-info">
                        <div className="row md-marg manage-wrap">
                          {SecretaryInfo.map((secretary) => (
                            <React.Fragment key={secretary.id}>
                              <div className="col-lg-6 col-sm-12">
                                <h6 className="sec-msg sub-title main-color mb-15">
                                  SECRETARY Message
                                </h6>
                                <h2 className="fw-600 d-rotate wow">
                                  <span className="rotate-text administration-text">
                                    {secretary.name}
                                  </span>
                                  <h6 className="administration-secretary text-u ls1 opacity-8 rotate-text">
                                    {secretary.position}
                                  </h6>
                                  <div className="info">
                                    <p className="nowrap">
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 20 20"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"
                                          ></path>
                                        </svg>
                                      </span>
                                      &nbsp; {secretary.mobile}
                                    </p>
                                    <p className="nowrap">
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M1.75 2h12.5c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0 1 14.25 14H1.75A1.75 1.75 0 0 1 0 12.25v-8.5C0 2.784.784 2 1.75 2M1.5 12.251c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25V5.809L8.38 9.397a.75.75 0 0 1-.76 0L1.5 5.809zm13-8.181v-.32a.25.25 0 0 0-.25-.25H1.75a.25.25 0 0 0-.25.25v.32L8 7.88Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      &nbsp; {secretary.email}
                                    </p>
                                  </div>
                                </h2>
                                <div className="d-flex align-items-end mt-30">
                                  <p className="mb-10">
                                    {messageParagraphs
                                      .slice(0, 3)
                                      .map((paragraph, index) => (
                                        <p
                                          key={index}
                                          className="secretary-message-paragraph"
                                        >
                                          {paragraph}
                                        </p>
                                      ))}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12">
                                <div className="about-img secretary-image">
                                  <img src={secretary.image} alt="image" />
                                </div>
                              </div>
                              <div className="">
                                {messageParagraphs
                                  .slice(3, 5)
                                  .map((paragraph, index) => (
                                    <p
                                      className="secretary-message-paragraph"
                                      key={index}
                                    >
                                      {paragraph}
                                    </p>
                                  ))}
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Administration;
