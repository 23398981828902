import { all, fork } from "redux-saga/effects";
import { HomeSagas } from "./Home/sagas";
import { psgiaqSagas } from "./psg-iaq/sagas";
import { TheTrustSagas } from "./The Trust/sagas";
import { SonsandCharitiesSagas } from "./PsgSonsCharities/sagas";
import { FactsandFiguresSagas } from "./FactsandFigures/sagas";
import { StatutoryCommitteeActionsSagas } from "./Statutory Committee/sagas";
import { AdministrationSagas } from "./Administration/sagas";
import { FooterSagas } from "./Footer/sagas";
import { DesignchairSagas } from "./design-chair/sagas";
import { PrincipalSagas } from "./Principal/sagas";
import { CorefacultySagas } from "./Core Faculty/sagas";
import { VisitingFacultySagas } from "./visiting-faculty/sagas";
import { AlliedFacultySagas } from "./Allied Faculty/sagas";
import { ExpertPanelMembersSagas } from "./expert-panel-memebers/sagas";
import { AdministrativeStaffsSagas } from "./Administrative Staffs/sagas";
import { AcademicCalendarSagas } from "./Academic Calendar/sagas";
import { AcademictimetableSagas } from "./Academic-time-table/sagas";
import { ProgramsSagas } from "./programs/sagas";
import { SyllabusSagas } from "./syllabus/sagas";
import { ConnectWithUsSagas } from "./Connect With Us/sagas";
import { LearningEventSagas } from "./Learning Events/sagas";
import { LifeAtCampusSagas } from "./LifeAtCampus/sagas";
import { IqacSagas } from "./IQAC/sagas";
import { WorkWithUsSagas } from "./Work with us/sagas";
import { AdmissionsSagas } from "./Admissions/sagas";

import { CocurricularSagas } from "./CoCurricular/sagas";

import { EditorialSagas } from "./Editorial/sagas";
import { CurricularSagas } from "./Curricular/sagas";
import {ExamCellSagas} from "./ExamCell/sagas"
export default function* rootSaga() {
  yield all([
    fork(HomeSagas),
    fork(psgiaqSagas),
    fork(TheTrustSagas),
    fork(SonsandCharitiesSagas),
    fork(FactsandFiguresSagas),
    fork(StatutoryCommitteeActionsSagas),
    fork(AdministrationSagas),
    fork(FooterSagas),
    fork(DesignchairSagas),
    fork(PrincipalSagas),
    fork(CorefacultySagas),
    fork(VisitingFacultySagas),
    fork(AlliedFacultySagas),
    fork(ExpertPanelMembersSagas),
    fork(AdministrativeStaffsSagas),
    fork(AcademicCalendarSagas),
    fork(AcademictimetableSagas),
    fork(ProgramsSagas),
    fork(SyllabusSagas),
    fork(ConnectWithUsSagas),
    fork(LearningEventSagas),
    fork(LifeAtCampusSagas),
    fork(IqacSagas),
    fork(WorkWithUsSagas),
    fork(AdmissionsSagas),
fork(ExamCellSagas),
    fork(CocurricularSagas),
    fork(EditorialSagas),
    fork(CurricularSagas)
  ]);
}
