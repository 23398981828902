import React, { useState, useEffect } from "react";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import { useDispatch, useSelector } from "react-redux";
import "aos/dist/aos.css";
import { DesignchairActions } from "../../../Store/design-chair/action";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import HeaderBackground from "../../../common/SubpagesBGimage";

function DesignChair() {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const routeNames = {
    "/design-chair": { menuName: "The People", subMenuName: "Design Chair" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    AOS.init({
      delay: 50,
      duration: 1000,
    });
  }, []);

  // useEffect(() => {
  //   dispatch({ type: DesignchairActions.GET_DESIGNCHAIR_SECTION_DETAILS });
  // }, []);

  const { DesignchairDetails } = useSelector(
    (state) => state?.DesignchairReducer
  );

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      {DesignchairDetails && DesignchairDetails.length > 0 && (
        <section className="about" data-scroll-index="2">
          <div className="container section-padding">
            <div className="row md-marg manage-wrap">
              <div className="col-lg-6">
                <div>
                  <h6 className="sub-title main-color mb-15 des-chair">Design Chair</h6>
                  <h2 className="fw-600 d-rotate wow design-chair-text">
                    <span className="rotate-text design-chair-texts">
                      {DesignchairDetails[0].chair_name}
                    </span>
                  </h2>
                  <div className="info">
                    <p className="nowrap">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="currentColor"
                            d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"
                          ></path>
                        </svg>
                      </span>
                      &nbsp;&nbsp;
                      <a href={`tel:${DesignchairDetails[0].contact_info}`}>
                        {DesignchairDetails[0].contact_info}
                      </a>
                    </p>
                    <div className="email-container-design-chair">
                      <p className="">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="currentColor"
                              d="M1.75 2h12.5c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0 1 14.25 14H1.75A1.75 1.75 0 0 1 0 12.25v-8.5C0 2.784.784 2 1.75 2M1.5 12.251c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25V5.809L8.38 9.397a.75.75 0 0 1-.76 0L1.5 5.809zm13-8.181v-.32a.25.25 0 0 0-.25-.25H1.75a.25.25 0 0 0-.25.25v.32L8 7.88Z"
                            ></path>
                          </svg>
                        </span>
                        &nbsp;&nbsp;
                        {DesignchairDetails[0].chair_email
                          .split(",")
                          .map((email, index) => (
                            <a
                              key={index}
                              href={`mailto:${email.trim()}`}
                              className="email-link-design-chair"
                            >
                              {email.trim()}
                              {index <
                              DesignchairDetails[0].chair_email.split(",")
                                .length -
                                1
                                ? " ,"
                                : ""}
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-end mt-30">
                    <div>
                      <p className="design-chair-content">
                        {DesignchairDetails[0].chair_content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="img-vid border2 design-img">
                  <img className="design-img"
                    src={DesignchairDetails[0].chair_image}
                    alt={DesignchairDetails[0].chair_name}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {DesignchairDetails && DesignchairDetails.length > 0 && (
        <section className="services-details section-padding">
          <div className="container">
            <div className="sec-head text-center mb-80">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div data-aos="zoom-in-up">
                    <h4 className="italic text-zoomin design-chair-quote res-italic-txts">
                      {`"${DesignchairDetails[0].chair_quote}"`}
                    </h4>
                    <div>
                      <span className="sub-title main-color mt-20 design-chair-author">
                        {DesignchairDetails[0].chair_quote_author}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default DesignChair;
