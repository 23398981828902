import React, { useState } from "react";
import graduation from "../../../assets/imgs/Curricular.jpg";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../../common/SubpagesBGimage";
import c1 from "../../../assets/imgs/curricular/curricular1.webp"
import c2 from "../../../assets/imgs/curricular/curricular2.webp"
import c3 from "../../../assets/imgs/curricular/curricular3.webp"
import c4 from "../../../assets/imgs/curricular/curricular4.webp"
import c5 from "../../../assets/imgs/curricular/curricular5.webp"
import cc1 from "../../../assets/imgs/CoCurricular/1 (1).webp"
import cc2 from "../../../assets/imgs/CoCurricular/2 (1) (1).webp"
import cc3 from "../../../assets/imgs/CoCurricular/20230605_162506 (1).webp"
import cc4 from "../../../assets/imgs/CoCurricular/20230605_162535 (1).webp"
import cc5 from "../../../assets/imgs/CoCurricular/20230605_162654 (1).webp"


function Curricular() {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState("Curricular");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  const routeNames = {
    "/curricular": {
      menuName: "Life At Campus",
      // subMenuName: "Curricular",
  }
};

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  const LifeAtCampusData = [
    {
      category: "Curricular",
      images: [c1, c2, c3, c4, c5], 
      // description: "Experience a variety of engaging curricular activities.",
    },
    {
      category: "Co-Curricular",
      images: [cc1,cc2,cc3,cc4,cc5], // Single image still wrapped in an array
      description: "Explore opportunities beyond the classroom.",
    },
    {
      category: "Extra-Curricular",
      images: [graduation],
      description: "Stay active and healthy with our sports programs.",
    },
    {
      category: "Collaboration",
      images: [graduation],
      description: "Celebrate diversity through cultural events.",
    },
  ];
  

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  return (
    <div>
      <main className="main-bg blog-wrap life-at-campus">
        <HeaderBackground
          menuName={menuName}
          // subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <section className="work-stand section-padding sub-bg ">
          <div className="container-xxl">
            <div className="row">
              <div className="filtering col-12 text-center life-at-campus-filtering">
                <div className="filter">
                  {LifeAtCampusData.map((item, index) => (
                    <span
                      key={index}
                      className={
                        item.category === activeCategory ? "active" : ""
                      }
                      onClick={() => handleCategoryChange(item.category)}
                    >
                      {item.category}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="gallery row stand-marg student-project-wrap ">
            {LifeAtCampusData.map((item) =>
      item.category === activeCategory ? (
        // Nested map for images
        item.images.map((image, imgIndex) => (
          <div key={imgIndex} className="col-lg-3 col-md-6">
            <div className="img">
              <img
                src={image} // Access individual image here
                alt={item.category}
                className="life-at-campus-image "
              />
            </div>
            {imgIndex === 0 && (
              <div>
                {/* <h5>{item.category}</h5> */}
                {/* <p>{item.description}</p> */}
              </div>
            )}
          </div>
        ))
      ) : null
    )}
</div>

          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Curricular;
