import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import StudentProjects from "../components/Student Portfolio/Student-Detailpage/index";
import Loading from "../common/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { HomePageActions } from "../Store/Home/action";
import { PageOpeningAnimation } from "../common/pageOpeningAnimation";
import gsap from 'gsap';
function StudentProjectListPage() {
  document.title = "StudentProjects";
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
    // const backgroundColor = "black";
    const textColor = "black";

    const { StudentPortfolioDetailpage } = useSelector(
      (state) => state.HomePageReducer
    );
  
    const [loading, setLoading] = useState(true);
    const [projectname, setProjectname] = useState("");
    const [description, setDescription] = useState("");
    const [images, setImages] = useState([]);
    
    useEffect(() => {
      window.scrollTo(0, 0);
  
      if (state?.projectId) {
        dispatch({
          type: HomePageActions.STUDENT_PORTFOLIO_DETAILPAGE,
          payload: {
            projectId: state.projectId,
          },
        });
      }
    }, [dispatch, state]);
  
    useEffect(() => {
      if (StudentPortfolioDetailpage) {
        setLoading(false);
        setProjectname(StudentPortfolioDetailpage.projectname || "");
        setDescription(StudentPortfolioDetailpage.description || "");
        setImages(StudentPortfolioDetailpage.images || []);
      } else {
        setLoading(true);
        setProjectname("");
        setDescription("");
        setImages([]);
      }
    }, [StudentPortfolioDetailpage]);
    useEffect(() => {
      if (!loading) {
        const svg = document.getElementById("svg");
        const tl = gsap.timeline({ repeat: 0, yoyo: true });
  
        tl.to('.bluee', {
          duration: 1,
          attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
          ease: 'power2.in',
        }).to('.bluee', {
          duration: 1,
          attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
          ease: 'power2.out',
        });
  
        // Start SVG animation after the loading text is hidden
        tl.add(() => {
          gsap.to(svg, {
            duration: 0.8,
            attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
            ease: 'power2.in',
          });
        });
  
        // Clean up animation on unmount
        return () => {
          tl.kill(); // Kill the animation to prevent memory leaks
        };
      }
    }, [loading]);
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <PageOpeningAnimation/>
          <Header Color={textColor} isInGallery={true} />
          {/* <Header backgroundColor={backgroundColor} /> */}
          <StudentProjects
            projectname={projectname}
            description={description}
            images={images}
          />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default StudentProjectListPage;