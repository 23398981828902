import React, { useState, useEffect } from "react";
import HeaderBackground from "../../../common/SubpagesBGimage";
import submenuservicon from "../../../assets/imgs/serv-icons/1.png";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../../Store/Home/action";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { EditorialActions } from "../../../Store/Editorial/action";

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function Editorial() {
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();

  const { Blog } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_BLOG });
  }, [dispatch]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const { editorial } = useSelector((state) => state?.EditorialReducer);

  useEffect(() => {
    dispatch({ type: EditorialActions.GET_EDITORIAL_SECTIONS });
  }, [dispatch]);

  const routeNames = {
    "/editorial": {
      menuName: "Learning Events",
      subMenuName: "Editorial",
    },
  };
  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const { editorialmenu } = useSelector((state) => state?.EditorialReducer);
  // console.log("Editt", editorialmenu);
  useEffect(() => {
    // console.log("Editorial Menu:", editorialmenu);
    dispatch({ type: EditorialActions.GET_EDITORIALMENU_SECTIONS });
  }, [dispatch, editorialmenu]);

  const sections = [
    {
      section: { section_id: 1, section_name: "Archigazette" },
      contents: Blog.map((item) => ({
        title: item.title.slice(0, 20) + "...",
        posted_by: item.posted_by,
        posted_on: item.posted_on,
        image: item.image,
        pdf: item.pdf,
      })),
    },
    // displays the rest two menus 
    ...editorialmenu?.data?.map((item, index) => ({
      section: {
        section_id: index + 2, // Assign unique IDs for dynamic sections
        section_name: item.title,
      },
      contents: [
        { section_content: item.description },
        ...(Array(10)
          .fill(0)
          .map((_, i) => item[`point${i + 1}`])
          .filter(Boolean) // Remove null or undefined points
          .map((point) => ({ section_content: point }))),
      ],
    })) || [],
  ];

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80 abt-uss">
        <div className="container">
          <div className="cal-action pb-10 mb-40 bord-thin-bottom about-us-texts">
            <h6 className="sub-title mb-15 main-color ">Editorial</h6>
          </div>
          <div className="text mb-50">
            <p className="para-psg">
              {editorial?.data?.length > 0
                ? editorial.data[0].name
                : "Loading..."}
            </p>
          </div>
          <div className="row lg-marg" id="tabs">
  {/* Tab Navigation */}
  <div className="col-lg-4 col-12 d-flex flex-column">
    <div className="serv-tab-link tab-links full-width pt-40">
      <ul className="rest">
        {sections.map((section, index) => (
          <li
            key={section.section.section_id}
            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
              activeTab === `tabs-${index + 1}` ? "current" : ""
            }`}
            onClick={() => handleTabClick(`tabs-${index + 1}`)}
          >
            {/* Mobile and Tablet View (Display numbers) */}
            <div className="u-line linee psg-iaq-tabs d-lg-none">
              <span className="fz-18 opacity-7 mr-15">{`0${index + 1}`}</span>
              <span className="text fz-20 psg-margin editorial-text">
                {section.section.section_name}
              </span>
            </div>

            {/* Desktop View (Display Icon) */}
            <div className="u-line linee psg-iaq-tabs d-none d-lg-block">
              <span className="icon ti-arrow-circle-right tabss-psg"></span>
              <span className="text fz-20 psg-margin editorial-text">
                {section.section.section_name}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>

  {/* Tab Content */}
  <div className="col-lg-8 col-12">
    <div className="serv-tab-cont md-mb80 sticky-item">
      {sections.map((section, index) => (
        <div
          key={section.section.section_id}
          className={`tab-content ${
            activeTab === `tabs-${index + 1}` ? "current" : ""
          }`}
        >
          {activeTab === "tabs-1" &&
            section.section.section_name === "Archigazette" && (
              <div className="row">
                {section.contents.map((content, index) => (
                  <div
                    key={index}
                    className="archi-card col-lg-4 col-md-6 col-sm-12 mb-4 mt-50"
                    onClick={() => window.open(content.pdf)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="archi-card-container">
                      {/* Hover Image */}
                      <div
                        className="archi-card-image"
                        style={{
                          backgroundImage: `url(${content.image})`,
                        }}
                      ></div>

                      {/* Card Content */}
                      <div className="archi-card-content">
                        <h6 className="archi-card-author">
                          {content.posted_by}
                        </h6>
                        <p className="archi-card-title">{content.title}</p>
                        <p className="archi-card-date">
                          {formatDate(content.posted_on)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

          {activeTab !== "tabs-1" && (
            <ul className="rest list-arrow mt-30 pt-30">
              <div className="item">
                <div className="sub-bg">
                  <div className="icon-img-60 mb-40">
                    <img src={submenuservicon} alt="" />
                  </div>
                  <div className="text">
                    <ul className="rest list-arrow mt-30 pt-30 bord-thin-top">
                      {section.contents.map((content, contentIndex) => (
                        <li key={contentIndex} className="pointtxt">
                          {section.contents.length > 1 && (
                            <span className="icon psgiap-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2.2em"
                                height="2.2em"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  cx="12.1"
                                  cy="12.1"
                                  r="1"
                                  fill="none"
                                  stroke="#84e3c6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                              </svg>
                            </span>
                          )}
                          <p className="inline fw-400 fz-16 text ml-5 mt-2">
                            {content.section_content}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          )}
        </div>
      ))}
    </div>
  </div>
</div>


        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Editorial;
