import { EditorialActions } from "./action";
import { takeEvery, call, all, put } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/Constant";
export const EditorialSagas = function* () {
  yield all([
    takeEvery(
      EditorialActions.GET_EDITORIAL_SECTIONS,
      getEditorialSections
    ),
    takeEvery(
      EditorialActions.GET_EDITORIALMENU_SECTIONS,
      getEditorialMenu
    )
  ]);
};

const getEditorialSections = function* () {
  console.log("inside sagas");
  
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/editoriol`) // Correct API URL
    );

    console.log("API Response:", result.data);

    yield put({
      type: EditorialActions.SET_EDITORIAL_SECTIONS,
      payload: result.data, // Pass API response to the reducer
    });
  } catch (error) {
    console.error("Error fetching editorial sections:", error);
  }
};
 const getEditorialMenu = function*(){
  try {
    const result = yield call(()=> axios.get(`${API_URL}/editoriol-menu`))
  // console.log("API", result.data);
  
    yield put({
    type:EditorialActions.SET_EDITORIALMENU_SECTIONS,
    payload:result.data,
  })
  } catch (error) {
    console.error("Error", error);
  }
 }