import React, { useEffect } from 'react';
import Header from '../components/Layout/Header'
import CoCurricular from "../components/Submenu/CoCurricular/CoCurricular"
import Footer from '../components/Layout/Footer'

function CoCurricularIndex() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header/>
      <CoCurricular/>
      <Footer/>
    </div>
  )
}

export default CoCurricularIndex
