export const ExamCellActions = {
    GET_EXAM_CELL: "GET_EXAM_CELL",
    SET_EXAM_CELL: "SET_EXAM_CELL",

    GET_EXAM_CELL_ABOUT: "GET_EXAM_CELL_ABOUT",
    SET_EXAM_CELL_ABOUT: "SET_EXAM_CELL_ABOUT",

    GET_EXAM_CELL_UNIRESULT: "GET_EXAM_CELL_UNIRESULT",
    SET_EXAM_CELL_UNIRESULT: "SET_EXAM_CELL_UNIRESULT",

    GET_EXAM_CELL_USEFUL_LINKS: "GET_EXAM_CELL_USEFUL_LINKS",
    SET_EXAM_CELL_USEFUL_LINKS: "SET_EXAM_CELL_USEFUL_LINKS",
  };
  