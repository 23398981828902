import React, { useEffect } from 'react';
import Header from '../components/Layout/Header'
import ExtraCurricular from '../components/Submenu/ExtraCurricular/ExtraCurricular'
import Footer from '../components/Layout/Footer'

function ExtraCurricularIndex() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header/>
      <ExtraCurricular/>
      <Footer/>
    </div>
  )
}

export default ExtraCurricularIndex