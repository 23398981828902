import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { HomePageActions } from "../Store/Home/action";

function BannerVideo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bannerFiles, setBannerFiles] = useState([]);

  const { Banner } = useSelector((state) => state.HomePageReducer);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  // useEffect(() => {
  //   dispatch({ type: HomePageActions.GET_HOME_HEADER_BANNER });
  // }, [dispatch]);

  useEffect(() => {
    if (Banner && Banner.length > 0) {
      const files = Banner.map((item) => ({
        type: item.file.endsWith(".mp4") ? "video" : "image",
        url: item.file,
      }));
      setBannerFiles(files);
    }
  }, [Banner]);

  return (
    <div>
      {bannerFiles.length > 0 &&
        (bannerFiles[0].type === "video" ? (
          // Render video banner
          <section className="banner-wrap">
           <div className="video-wrap">
  <div className="bg-video-wrap">
    <video src={bannerFiles[0].url} loop muted autoPlay />
  </div>
</div>

            <div className="banner-content">
              <div className="main-header bg-img">
                <div className="container ontop">
                  <div className="lg-text">
                    <div className="d-flex">
                      <h1 className="banner-text">
                        PSG Institute of <br />
                        Architecture and Planning
                      </h1>
                      </div>
                    <div className="d-flex">
                    <p className="white">Approved by Council of Architecure New Delhi | Affiliated to Anna University,Chennai</p> </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          // Render image slider
          <section className="banner-wrap">
            <div className="video-wrap">
              <div className="bg-video-wrap">
                <Slider {...settings}>
                  {bannerFiles.map((file, index) => (
                    <div key={index}>
                      <img src={file.url} alt={`Slide ${index + 1}`} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="banner-content">
              <div className="main-header bg-img">
                <div className="container ontop">
                  <div className="lg-text">
                    <div className="d-flex">
                      <h1 className="banner-text">
                        PSG Institute of <br />
                        Architecture and Planning
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
    </div>
  );
}

export default BannerVideo;
