import { EditorialActions } from "./action";

const initialState={
    editorial:[],
    editorialmenu:[]
}

export const EditorialReducer = (state = initialState, action) => {
    switch (action.type) {
      case EditorialActions.SET_EDITORIAL_SECTIONS:
        return {
          ...state,
          editorial: action.payload, // Set the API response here
        };
        case EditorialActions.SET_EDITORIALMENU_SECTIONS:
            return{
                ...state,
                editorialmenu: action.payload,
            }
      default:
        return state;
    }
  };