export const ExamCellActions = {
     GET_EXAMCELLACTIONS: "GET_EXAMCELLACTIONS",
     SET_EXAMCELLACTIONS: "SET_EXAMCELLACTIONS",

     GET_LIBRARY_ACTIONS: "GET_LIBRARY_ACTIONS",
     SET_LIBRARY_ACTIONS: "SET_LIBRARY_ACTIONS",

     GET_LABS_ACTIONS: "GET_LABS_ACTIONS",
     SET_LABS_ACTIONS: "SET_LABS_ACTIONS",


   };