import { all, call, put, takeEvery } from "redux-saga/effects";
import { ExamCellActions } from "./action";
import axios from "axios";
import { API_URL } from "../../utils/Constant";

export const ExamCellSagas = function* () {
  yield all([takeEvery(ExamCellActions.GET_EXAM_CELL, getExamCell),

    takeEvery(ExamCellActions.GET_EXAM_CELL_ABOUT, getExamCellAbout),
    takeEvery(ExamCellActions.GET_EXAM_CELL_UNIRESULT,getExamCellUniRes),
    takeEvery(ExamCellActions.GET_EXAM_CELL_USEFUL_LINKS,getExamCellUsefulLinks)
  ]);
};


const getExamCell = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/examcell-people`));
   
   
    yield put({
      type: ExamCellActions.SET_EXAM_CELL,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};


const getExamCellAbout = function* (data) {
    try {
      const result = yield call(() => axios.get(`${API_URL}/examcell-about`));
      console.log("about", result);
    //  console.log("people", result);
     
      yield put({
        type: ExamCellActions.SET_EXAM_CELL_ABOUT,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getExamCellUniRes = function* (data) {
    try {
      const result = yield call(() => axios.get(`${API_URL}/universities`));
      console.log("uni", result);

     
      yield put({
        type: ExamCellActions.SET_EXAM_CELL_UNIRESULT,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getExamCellUsefulLinks = function* (data) {
    try {
      const result = yield call(() => axios.get(`${API_URL}/UsefullLinks`));
      console.log("links", result);

      yield put({
        type: ExamCellActions.SET_EXAM_CELL_USEFUL_LINKS,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };