import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { StatutoryCommitteeActions } from "./action";

export const StatutoryCommitteeActionsSagas = function* () {
  yield all([
    takeEvery(
      StatutoryCommitteeActions.GET_STATUTORY_COMMITTEE,
      getStatutoryCommittee
    ),
     takeEvery(
      StatutoryCommitteeActions.GET_STATUTORY_COMMITTEE_SUBPAGE,
      getStatutoryCommitteeSubpage
    ),
  ]);
};

const getStatutoryCommittee = function* (data) {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/institutional_committee`)
    );

    yield put({
      type: StatutoryCommitteeActions.SET_STATUTORY_COMMITTEE,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};


const getStatutoryCommitteeSubpage = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/committees`)
    );

    yield put({
      type: StatutoryCommitteeActions.SET_STATUTORY_COMMITTEE_SUBPAGE,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error fetching statutory committee subpage data:", error);
  }
};