import React, { useState, useEffect } from "react";
import graduation from "../../../assets/imgs/submenu/graduation.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrincipalActions } from "../../../Store/Principal/action";
import HeaderBackground from "../../../common/SubpagesBGimage";

function Principal() {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const routeNames = {
    "/principal": { menuName: "The People", subMenuName: "Principal" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    AOS.init({
      delay: 50,
      duration: 1000,
    });
  }, []);

  // useEffect(() => {
  //   dispatch({ type: PrincipalActions.GET_ABOUTPRINCIPAL });
  // }, []);

  const { Principalinfo } = useSelector((state) => state?.PrincipalReducer);

  const principalData = Principalinfo?.[0];

  return (
    <div>
      {/* <header
        class="page-header-cerv bg-img bg-center section-padding "
        style={{ backgroundImage: `url(${graduation})` }}
        data-overlay-dark="4"
      >
        <div class="container pt-100 ontop">
          <div className="text-left">
            <div>
              <a href="/">Home</a>
              <span className="padding-rl-10">|</span>
              <a onClick={handleMenuToggle}>{menuName}</a>
              <span className="padding-rl-10">|</span>
              <span>{subMenuName || "Submenu"}</span>
            </div>
          </div>
        </div>
      </header> */}
      <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />

      <div class="container section-padding princ-text principal-entire-page">
        <div class="row md-marg manage-wrap">
          <div class="col-lg-6">
            <div class="cont md-mb50 princi-content">
              <h6 class="sub-title main-color mb-15">Team</h6>
              <h2 class="fw-400 d-rotate wow">
                <span className="rotate-text principal-text">
                  {principalData?.name || "Name not available"}
                </span>
              </h2>
              <div class="info">
                <p class="nowrap">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="currentColor"
                        d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"
                      ></path>
                    </svg>
                  </span>
                  &nbsp; &nbsp;
                  <a href={`tel:${principalData?.number?.split(",")[0]}`}>
                    {principalData?.number?.split(",")[0] ||
                      "Phone not available"}
                  </a>
                  ,
                  <a href={`tel:${principalData?.number?.split(",")[1]}`}>
                    {principalData?.number?.split(",")[1] || ""}
                  </a>
                </p>
                <p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M1.75 2h12.5c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0 1 14.25 14H1.75A1.75 1.75 0 0 1 0 12.25v-8.5C0 2.784.784 2 1.75 2M1.5 12.251c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25V5.809L8.38 9.397a.75.75 0 0 1-.76 0L1.5 5.809zm13-8.181v-.32a.25.25 0 0 0-.25-.25H1.75a.25.25 0 0 0-.25.25v.32L8 7.88Z"
                      ></path>
                    </svg>
                  </span>
                  &nbsp; &nbsp;
                  <a href={`mailto:${principalData?.mail?.split(",")[0]}`}>
                    {principalData?.mail?.split(",")[0] ||
                      "Email not available"}
                  </a>
                  ,
                  <a href={`mailto:${principalData?.mail?.split(",")[1]}`}>
                    {principalData?.mail?.split(",")[1] || ""}
                  </a>
                </p>
              </div>
              <div class="d-flex align-items-end mt-30">
                <div style={{textAlign:"justify"}}>
                  <p>
                    {principalData?.description || "Description not available"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="img-vid border2">
              <img src={principalData?.file} alt="" className="princi-image" />
            </div>
          </div>
        </div>
      </div>

      <section className="services-details section-padding">
        <div className="container">
          <div className="sec-head text-center mb-80">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div data-aos="zoom-in-up ">
                  <h4 className="italic princi-animated-text">
                    {`“${principalData?.quotes || "Quote not available"}”`}
                  </h4>
                  <div>
                    <span className="sub-title main-color mt-20">
                      {principalData?.author || "Author not available"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Principal;
