
import { takeEvery, call, all, put, take } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { CurricularActions } from "../Curricular/action";

export const CurricularSagas = function* () {
  console.log("CurricularSagas running...");
  yield all([
    takeEvery(CurricularActions.GET_CURRICULAR, getLifeAtCampus),
  ]);
};

const getLifeAtCampus = function* (data) {
  try {
  
    const result = yield call(() => axios.get(`${API_URL}/curricular`));
  console.log("curricular",result);
  
    yield put({
      type: CurricularActions.SET_CURRICULAR,
      payload: result.data.data,
    });
  } catch (error) {
    console.error("Error in API Call:", error);
  }
};



